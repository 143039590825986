// @import "@staplcotn/diamond-theme/assets/layout/css/layout-light.scss";
@import "@staplcotn/diamond-theme/assets/colors.scss";
$pt-grid-size: 10px !default;
$pt-icon-size-standard: 16px !default;
$pt-icon-size-large: 20px !default;
// $actionIconColor: $shade100 !default;
.heading {
  font-weight: 600;
}

.callout {
  background-color: rgba($gray3, 0.15);

  border-radius: 4px;
  padding: $pt-grid-size ($pt-grid-size * 1.2) ($pt-grid-size * 0.9);
  position: relative;
  width: 100%;
  // CSS API support
  &[class*="icon-"] {
    padding-left: ($pt-grid-size * 2) + $pt-icon-size-large;

    &::before {
      // @include pt-icon($pt-icon-size-large);
      // color: $actionIconColor;
      left: $pt-grid-size;
      position: absolute;
      top: $pt-grid-size;
    }
  }

  &.callout-icon {
    padding-left: ($pt-grid-size * 2) + $pt-icon-size-large;

    > .icon:first-child {
      //   color: $pt-icon-color;
      left: $pt-grid-size;
      position: absolute;
      top: $pt-grid-size;
    }
  }

  .heading {
    line-height: $pt-icon-size-large;
    margin-bottom: $pt-grid-size / 2;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
  @each $intent, $color in $pt-intent-colors {
    &.intent-#{$intent} {
      background-color: rgba($color, 0.15);
    }
  }
  .running-text & {
    margin: ($pt-grid-size * 2) 0;
  }
}
.icon {
  // ensure icons sit inline with text & isolate svg from surrounding elements
  // (vertical alignment in flow is usually off due to svg - not an issue with flex.)
  display: inline-block;
  // respect dimensions exactly
  flex: 0 0 auto;
  // sit nicely with inline text
  vertical-align: text-bottom;
}
