.grid-container {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  /* grid-template-rows: 4em 1fr 0.25fr auto; */
  gap: 10px 20px;
  grid-auto-flow: row;
  align-items: stretch;
  grid-template-areas: "data-left data-right";
}

.header-left {
  justify-self: normal;
  grid-area: header-left;
}

.header-right {
  align-self: center;
  text-align: left;
  align-content: flex-start;
  grid-area: header-right;
}

.data-left {
  align-items: stretch;
  grid-area: data-left;
}

.data-right {
  grid-area: data-right;
  justify-items: end;
  align-items: end;
}

.data-button {
  grid-area: data-button;
  height: 1em;
}
@media screen and (min-width: 960px) {
  .data-left {
    min-width: 600px;
  }
}
@media screen and (max-width: 960px) {
  .grid-container {
    display: grid;
    grid-template-columns: 6fr;
    grid-template-rows: 0fr 0.25fr 0.25fr 1fr;
    gap: 1em 0px;
    grid-auto-flow: row;
    justify-content: stretch;
    grid-template-areas:
      "header-left"
      "data-left"
      "header-right"
      "data-right"
      "data-button";
    width: 100%;
    /* height: 960px; */
  }
}
