.layout-topbar {
  height: 5rem;
}
@media screen and (max-width: 900px) {
  .layout-content-wrapper {
    padding-top: 5rem !important;
  }
}
.text-centered {
  text-align: center;
}

.text-all-caps {
  text-transform: uppercase;
}
.heading + .subtitle {
  margin-top: -0.5rem;
}
.row-static-height {
  height: 50px !important;
}
.text-muted {
  color: var(--gray1);
}
gin-top-1 {
  margin-top: "0.5rem";
}
:root {
  --beige: #e6e6e1;
}
